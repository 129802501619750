import { MetrologyDataRequest } from "../../pages/metrology/page";
import { AnnotationShapeOptions, MeasurementDimensions } from "../../types";
import { StatsDisplayNames } from "./inspectionTableData";


export enum AutomatedInspectionMetrics {
    // Not necessarily in the DB.
    CORE_AREA = "core_area",
    CORE_CIRCULARITY_DMIN_DMAX = "core_circularity_dmin_dmax",
    CORE_CIRCULARITY_MCC = "core_circularity_mcc",
    CAN_INNER_DIAMETER_MEAN = "can_inner_diameter_mean",
    CAN_OUTER_DIAMETER_MEAN = "can_outer_diameter_mean",
    CAN_WALL_THICKNESS_MEAN = "can_wall_thickness_mean",
    ELECTRODE_BUCKLING_DETECTED = "electrode_buckling_detected",
    INSUFFICIENT_ANODE_OVERHANG = "insufficient_anode_overhang",
    ANODE_OVERHANG_ALL = "anode_overhang",
    ANODE_OVERHANG_TOP = "anode_overhang_top",
    ANODE_OVERHANG_BOTTOM = "anode_overhang_bottom",
    ANODE_OVERHANG_ASYMMETRY = "anode_overhang_asymmetry",
    CATHODE_WIDTH = "cathode_width",
    CATHODE_POSITION_TOP = "cathode_position_top",
    CATHODE_POSITION_BOTTOM = "cathode_position_bottom",
    CAN_CIRCULARITY_DMIN_DMAX = "can_circularity_dmin_dmax",
    CAN_MAX_DENTING = "can_max_denting",
    CORE_EFFECTIVE_DIAMETER = "core_effective_diameter",
    CORE_CONCENTRICITY = "core_concentricity",
    ANODE_TAB_CURVATURE = "anode_tab_curvature",
    LEFT_CRIMP_HEIGHT = "left_crimp_height",
    RIGHT_CRIMP_HEIGHT = "right_crimp_height",
}


export const automatedListOrder = [
    AutomatedInspectionMetrics.CORE_AREA,
    AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX,
    AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER,
    AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC,
    AutomatedInspectionMetrics.CORE_CONCENTRICITY,
    AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN,
    AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN,
    AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN,
    AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX,
    AutomatedInspectionMetrics.CAN_MAX_DENTING,
    AutomatedInspectionMetrics.ELECTRODE_BUCKLING_DETECTED,
    AutomatedInspectionMetrics.ANODE_TAB_CURVATURE,
    AutomatedInspectionMetrics.ANODE_OVERHANG_ALL,
    AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY,
    AutomatedInspectionMetrics.ANODE_OVERHANG_TOP,
    AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM,
    AutomatedInspectionMetrics.CATHODE_WIDTH,
    AutomatedInspectionMetrics.CATHODE_POSITION_TOP,
    AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM,
    AutomatedInspectionMetrics.INSUFFICIENT_ANODE_OVERHANG,
    AutomatedInspectionMetrics.LEFT_CRIMP_HEIGHT,
    AutomatedInspectionMetrics.RIGHT_CRIMP_HEIGHT,
] as string[]

export enum MetricChartPageOptions {
    //  This is for the chart page (multiple scans) dropdown display:
    CAN_WALL_THICKNESS_MEAN = 'Mean can wall thickness',
    CAN_INNER_DIAMETER_MEAN = 'Mean can inner diameter',
    CAN_OUTER_DIAMETER_MEAN = 'Mean can outer diameter',
    CORE_AREA = 'Jellyroll core area',
    CORE_CIRCULARITY_DMIN_DMAX = 'Jellyroll core eccentricity',
    CORE_CIRCULARITY_MCC = 'Jellyroll core minimum circumscribed circle',
    ANODE_OVERHANG_ALL = 'Electrode overhang - all',
    ANODE_TOP_BOTTOM_ASYSMMETRY = 'Electrode overhang - top/bottom asymmetry',
    ANODE_OVERHANG_TOP = 'Electrode overhang - top of cell',
    ANODE_OVERHANG_BOTTOM = 'Electrode overhang - bottom of cell',
    CATHODE_WIDTH = 'Cathode width',
    CAN_CIRCULARITY_DMIN_DMAX = 'Can eccentricity',
    CAN_MAX_DENTING = 'Maximum can denting',
    CORE_EFFECTIVE_DIAMETER = 'Core effective diameter',
    CORE_CONCENTRICITY = 'Core concentricity',
    ANODE_TAB_CURVATURE = 'Anode tab curvature',
    LEFT_CRIMP_HEIGHT = 'Left crimp height',
    RIGHT_CRIMP_HEIGHT = 'Right crimp height',


}

export const findMetricName = (id: number, availableMetrics: MeasurementDimensions[]) => {
    return availableMetrics.find((m) => m.metric_id === id)?.metric_internal_name as AutomatedInspectionMetrics || ""
}


export const selectedMetricData = (
    allMetrology: MetrologyDataRequest[],
    availableMetrics: MeasurementDimensions[],
    selectedMetric: MetricChartPageOptions) => {
    //  this function kind of sucks, TODO: redo it.
    const filteredData = (metric_internal_name: string) => {
        return allMetrology.filter(
            (e) => availableMetrics.find((f) => f.metric_internal_name === metric_internal_name)?.metric_id === e.metric_id
        )
    }
    switch (selectedMetric) {
        case MetricChartPageOptions.CORE_AREA:
            return filteredData(AutomatedInspectionMetrics.CORE_AREA)
        case MetricChartPageOptions.CORE_CIRCULARITY_MCC:
            return filteredData(AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC)
        case MetricChartPageOptions.CAN_WALL_THICKNESS_MEAN:
            return filteredData(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN)
        case MetricChartPageOptions.CAN_INNER_DIAMETER_MEAN:
            return filteredData(AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN)
        case MetricChartPageOptions.CAN_OUTER_DIAMETER_MEAN:
            return filteredData(AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN)
        case MetricChartPageOptions.CORE_CIRCULARITY_DMIN_DMAX:
            return filteredData(AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX)
        case MetricChartPageOptions.ANODE_TOP_BOTTOM_ASYSMMETRY:
            return filteredData(AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY)
        case MetricChartPageOptions.ANODE_OVERHANG_ALL:
            return filteredData(AutomatedInspectionMetrics.ANODE_OVERHANG_ALL)
        case MetricChartPageOptions.ANODE_OVERHANG_TOP:
            return filteredData(AutomatedInspectionMetrics.ANODE_OVERHANG_TOP)
        case MetricChartPageOptions.ANODE_OVERHANG_BOTTOM:
            return filteredData(AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM)
        case MetricChartPageOptions.CATHODE_WIDTH:
            return filteredData(AutomatedInspectionMetrics.CATHODE_WIDTH)
        case MetricChartPageOptions.CAN_CIRCULARITY_DMIN_DMAX:
            return filteredData(AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX)
        case MetricChartPageOptions.CAN_MAX_DENTING:
            return filteredData(AutomatedInspectionMetrics.CAN_MAX_DENTING)
        case MetricChartPageOptions.CORE_EFFECTIVE_DIAMETER:
            return filteredData(AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER)
        case MetricChartPageOptions.CORE_CONCENTRICITY:
            return filteredData(AutomatedInspectionMetrics.CORE_CONCENTRICITY)
        case MetricChartPageOptions.ANODE_TAB_CURVATURE:
            return filteredData(AutomatedInspectionMetrics.ANODE_TAB_CURVATURE)
        case MetricChartPageOptions.LEFT_CRIMP_HEIGHT:
            return filteredData(AutomatedInspectionMetrics.LEFT_CRIMP_HEIGHT)
        case MetricChartPageOptions.RIGHT_CRIMP_HEIGHT:
            return filteredData(AutomatedInspectionMetrics.RIGHT_CRIMP_HEIGHT)
        default:
            return [] as MetrologyDataRequest[]
    }
}

// TODO: duplicated now with update, for the CHART
export const metricDisplayUnit = (metric: MetricChartPageOptions) => {
    switch (metric) {
        case MetricChartPageOptions.ANODE_OVERHANG_ALL:
        case MetricChartPageOptions.ANODE_OVERHANG_TOP:
        case MetricChartPageOptions.ANODE_OVERHANG_BOTTOM:
        case MetricChartPageOptions.CATHODE_WIDTH:
        case MetricChartPageOptions.CAN_WALL_THICKNESS_MEAN:
        case MetricChartPageOptions.CAN_INNER_DIAMETER_MEAN:
        case MetricChartPageOptions.CAN_OUTER_DIAMETER_MEAN:
        case MetricChartPageOptions.ANODE_TAB_CURVATURE:
        case MetricChartPageOptions.LEFT_CRIMP_HEIGHT:
        case MetricChartPageOptions.RIGHT_CRIMP_HEIGHT:
            return "mm"
        case MetricChartPageOptions.CORE_AREA:
            return "mm²"
        case MetricChartPageOptions.CORE_CIRCULARITY_MCC:
        case MetricChartPageOptions.CORE_CIRCULARITY_DMIN_DMAX:
        case MetricChartPageOptions.ANODE_TOP_BOTTOM_ASYSMMETRY:
            return "%"
        default:
            return ""
    }
}

export const metricDisplayUnitSliceViewer = (metric: string) => {
    switch (metric) {
        case AutomatedInspectionMetrics.ANODE_OVERHANG_ALL:
        case AutomatedInspectionMetrics.ANODE_OVERHANG_TOP:
        case AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM:
        case AutomatedInspectionMetrics.CATHODE_WIDTH:
        case AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN:
        case AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN:
        case AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN:
        case AutomatedInspectionMetrics.CATHODE_POSITION_TOP:
        case AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM:
        case AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER:
        case AutomatedInspectionMetrics.CAN_MAX_DENTING:
        case AutomatedInspectionMetrics.CORE_CONCENTRICITY:
        case AutomatedInspectionMetrics.ANODE_TAB_CURVATURE:
        case AutomatedInspectionMetrics.LEFT_CRIMP_HEIGHT:
        case AutomatedInspectionMetrics.RIGHT_CRIMP_HEIGHT:
            return "mm"
        case AutomatedInspectionMetrics.CORE_AREA:
            return "mm²"
        case AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC:
        case AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX:
        case AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY:
        case AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX:
            return "%"
        default:
            return ""
    }
}

export const MetricDisplayNames = [
    // Source of truth for naming in the slice view tables:
    {
        display_name: "Minimum circumscribed circle",
        description: "Circularity of the core, as defined by the maximum deviation from a minimum circumscribed circle (MCC) and normalized by radius",
        internal_name: AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC,
    },
    {
        display_name: "Eccentricity",
        description: "Circularity of the core, as obtained via the ratio of the min and max diameters from an ellipse fit",
        internal_name: AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX,
    },
    {
        display_name: "Mean inner diameter",
        description: "Inner diameter of the can, averaged over a slice",
        internal_name: AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN,
    },
    {
        display_name: "Mean outer diameter",
        description: "Outer diameter of the can, averaged over a slice",
        internal_name: AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN,
    },
    {
        display_name: "Area",
        description: "Area of the core (exact definition may vary based on the cell model)",
        internal_name: AutomatedInspectionMetrics.CORE_AREA,
    },
    {
        display_name: "Cathode buckling detected?",
        description: "True if electrode buckling has been detected in the jellyroll",
        internal_name: AutomatedInspectionMetrics.ELECTRODE_BUCKLING_DETECTED,
    },
    {
        display_name: "Mean wall thickness",
        description: "Thickness of the can wall, averaged over a slice (outer radius - inner radius)",
        internal_name: AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN,
    },
    {
        display_name: AutomatedInspectionMetrics.CATHODE_WIDTH,
        description: "Measured cathode width (jellyroll \"height\" in an axial slice). Currently, this measurement is approximated by a straight line between the top and bottom endpoints.",
        internal_name: AutomatedInspectionMetrics.CATHODE_WIDTH,
    },
    {
        display_name: StatsDisplayNames.MEAN,
        description: "Cathode wrap \"height\" at the top of the cell. The position is defined from the top of the image",
        internal_name: AutomatedInspectionMetrics.CATHODE_POSITION_TOP,
    },
    {
        display_name: StatsDisplayNames.MEAN,
        description: "Cathode wrap \"height\" at the bottom of the cell. The position is defined from the top of the image",
        internal_name: AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM,
    },
    {
        display_name: StatsDisplayNames.MEAN,
        description: "Anode overhang lengths detected at the top and bottom of the cell",
        internal_name: AutomatedInspectionMetrics.ANODE_OVERHANG_ALL,
    },
    {
        display_name: StatsDisplayNames.MEAN,
        description: "Anode overhang lengths detected at the bottom of the cell",
        internal_name: AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM,
    },
    {
        display_name: StatsDisplayNames.MEAN,
        description: "Anode overhang length detected at the top of the cell",
        internal_name: AutomatedInspectionMetrics.ANODE_OVERHANG_TOP,
    },
    {
        display_name: "Top/bottom overhang asymmetry",
        description: "A measure of the asymmetry of the overhangs, where 0% is perfectly centered. A positive value indicates average overhang is longer at the top, and a negative value indicates average overhang is longer at the bottom.",
        internal_name: AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY,
    },
    {
        display_name: "Electrode overhang violation?",
        description: "True if insufficient anode overhang has been detected within the slice",
        internal_name: AutomatedInspectionMetrics.INSUFFICIENT_ANODE_OVERHANG,
    },
    {
        display_name: "Eccentricity",
        description: "Circularity of the can, as obtained via the ratio of the min and max diameters from an ellipse fit",
        internal_name: AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX,
    },
    {
        display_name: "Max denting",
        description: "Largest dent detected, as defined by the maximum inward deviation from an ellipse fit. Only dents larger than 3 voxels are flagged.",
        internal_name: AutomatedInspectionMetrics.CAN_MAX_DENTING,
    },
    {
        display_name: "Effective diameter",
        description: "Geometric mean (√ab) of the major and the minor axes from an ellipse fit",
        internal_name: AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER,
    },
    {
        display_name: "Concentricity",
        description: "Distance from the can center (center of the image) to the center of an ellipse fit of the core",
        internal_name: AutomatedInspectionMetrics.CORE_CONCENTRICITY,
    },
    {
        display_name: "Anode tab curvature",
        description: "Curvature of the core anode tab, as defined by the radius of curvature of a circle fit",
        internal_name: AutomatedInspectionMetrics.ANODE_TAB_CURVATURE,
    },
    {
        display_name: "Left crimp height",
        description: "Height of the left crimp, as defined by the vertical distance between the top and bottom endpoints",
        internal_name: AutomatedInspectionMetrics.LEFT_CRIMP_HEIGHT,
    },
    {
        display_name: "Right crimp height",
        description: "Height of the right crimp, as defined by the vertical distance between the top and bottom endpoints",
        internal_name: AutomatedInspectionMetrics.RIGHT_CRIMP_HEIGHT,
    },
]


export interface Stats {
    min: number;
    max: number;
    mean: number;
    stdDev: number;
}

export function calculateStats(numbers: number[]): Stats {
    if (numbers.length === 0) { return { min: 0, max: 0, mean: 0, stdDev: 0 }; }
    let min = numbers[0], max = numbers[0], sum = 0, varianceSum = 0;
    // for loops are faster than array.map and .reduce functions, allegedly.
    for (const num of numbers) {
        if (num < min) min = num;
        if (num > max) max = num;
        sum += num;
    }
    const mean = sum / numbers.length;
    for (const num of numbers) {
        varianceSum += (num - mean) ** 2;
    }
    const stdDev = Math.sqrt(varianceSum / numbers.length);
    return { min, max, mean, stdDev };
}


export const conditionallyApplyPadding = (
    coordinates: number[] | number[][],
    shapeType: AnnotationShapeOptions,
    imageMeasurementPadding: { horizontal: number, vertical: number }
) => {
    switch (shapeType) {
        case AnnotationShapeOptions.ELLIPSE_AND_LINES:
            // TODO check length of array? or do that upstream in API?
            return coordinates.map((e, index) => {
                if (Array.isArray(e)) { return e }
                // TODO: yikes.
                // ellipse and two lines [x,y,r1,r2,theta,x1,y1,x2,y2,x3,y3,x4,y4]
                if ([0, 5, 7, 9, 11].includes(index)) return e + imageMeasurementPadding.horizontal
                if ([1, 6, 8, 10, 12].includes(index)) return e + imageMeasurementPadding.vertical
                return e
            })
        case AnnotationShapeOptions.CIRCLE_AND_LINE:
            return coordinates.map((e, index) => {
                if (Array.isArray(e)) { return e }
                // Circle and line [x,y,r1,x1,y1,x2,y2]
                if ([0, 3, 5].includes(index)) return e + imageMeasurementPadding.horizontal
                if ([1, 4, 6].includes(index)) return e + imageMeasurementPadding.vertical
                return e
            })
        default:
            return coordinates.map((e) => {
                if (Array.isArray(e)) {
                    // TODO: could type check more here with .length
                    return [e[0] + imageMeasurementPadding.horizontal, e[1] + imageMeasurementPadding.vertical]
                }
                return e
            })
    }
}

export const calculateMaxMin = (arr: number[]) => { return { max: Math.max(...arr), min: Math.min(...arr) } }

export const getMetricWithStats = (numbers: number[]) => {
    const uniqueNumbers = new Set<number>();
    numbers.filter(number => number > 10000)
        .forEach(number => uniqueNumbers.add(Math.floor(number / 1000)));
    return Array.from(uniqueNumbers);
}

export const highlightAutomaticInspection = (id: number, color: string, highlightedId: number, highlightedSource: string) => {
    if (id === highlightedId && highlightedSource === "automated") {
        return color.slice(0, -2)
    }
    return color
}

export const getIdFromInternalName = (internal_name: string, availableMetrics: MeasurementDimensions[]) => {
    return availableMetrics.find((m) => m.metric_internal_name === internal_name)?.metric_id || 0
}
