import { Box, MenuItem, Select, Stack, Typography } from '@mui/material';
import { OperatorGlimpseboxList } from '../../types';

export default function GlimpseboxSelector(
    {
        selectedGlimpsebox,
        glimpseboxes,
        handleGlimpseboxChange
    }: {
        selectedGlimpsebox: OperatorGlimpseboxList,
        glimpseboxes: OperatorGlimpseboxList[]
        handleGlimpseboxChange: (glimpsebox: OperatorGlimpseboxList) => void
    }
) {
    return (
        <>
            <Box sx={{ maxWidth: 1200, margin: "auto" }}>
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{ alignItems: "center" }}
                >
                    <Typography variant="body1">Viewing logs of system: </Typography>
                    <Select
                        size='small'
                        value={selectedGlimpsebox?.glimpsebox_user_id || ''}
                        onChange={(e) => {
                            const selectedGlimpsebox = glimpseboxes.find((glimpsebox) => glimpsebox.glimpsebox_user_id === e.target.value);
                            if (selectedGlimpsebox) { handleGlimpseboxChange(selectedGlimpsebox) }
                        }}
                        sx={{
                            width: 300
                        }}
                    >
                        {glimpseboxes.map((glimpsebox) => (
                            <MenuItem
                                key={glimpsebox.glimpsebox_user_id}
                                value={glimpsebox.glimpsebox_user_id}
                            >
                                {glimpsebox.email.replace('@glimp.se', '')}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
            </Box>
        </>
    );
}
