

import { AutomatedInspectionMetrics, MetricDisplayNames, Stats, calculateStats, getIdFromInternalName, getMetricWithStats, metricDisplayUnitSliceViewer } from "."
import { AutomatedInspectionTableData } from "../../components/SliceViewerPage/AutomatedMeasuremetList"
import { MeasurementDimensions, RawCathodeTraceResults, RawElectrodeOverhangTraceResults, RawGeneralInspectionResults } from "../../types"
import { colorAnnotation, overlayColorScheme } from "../colorScheme"

export enum StatsDisplayNames {
    MIN = "Minimum",
    MAX = "Maximum",
    MEAN = "Mean",
    STD_DEV = "Standard deviation"
}

export enum StatsIds {
    MIN = 1,
    MAX = 2,
    MEAN = 3,
    STD_DEV = 4
}

export const addCanWallThickness = (
    loadedMetrics: AutomatedInspectionTableData[],
    availableMetrics: MeasurementDimensions[],
    visibleInspectionMetricIds: number[]
) => {

    const availableInternalNames = availableMetrics.map((e) => e.metric_internal_name)
    const innerDiameter = loadedMetrics.find((e) => e.internal_name === AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN)?.result
    const outerDiameter = loadedMetrics.find((e) => e.internal_name === AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN)?.result
    if (
        availableInternalNames.includes(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN)
        && availableInternalNames.includes(AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN)
        && availableInternalNames.includes(AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN)
        && outerDiameter && innerDiameter
    ) {
        const displayInformation = MetricDisplayNames.find((e) => e.internal_name === AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN)
        const canWallThicknessId = getIdFromInternalName(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN, availableMetrics)
        const canWallThickness = {
            id: canWallThicknessId,
            internal_name: AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN,
            display_name: displayInformation?.display_name || "",
            result: (innerDiameter && outerDiameter) ? (outerDiameter - innerDiameter) / 2 : 0,
            display: visibleInspectionMetricIds.includes(canWallThicknessId ?? 0),
            description: displayInformation?.description || "",
            display_units: metricDisplayUnitSliceViewer(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN),
            color: colorAnnotation(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN),
            group_id: groupingId(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN).id,
            group_name: groupingId(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN).group_display_name
        } as AutomatedInspectionTableData
        return canWallThickness
    }
    return null
}

const getAnodeAsymmetry = (resultsAtSlice: AutomatedInspectionTableData[], visibleInspectionMetricIds: number[], availableMetrics: MeasurementDimensions[]) => {
    const top = resultsAtSlice.find((e) => e.internal_name === AutomatedInspectionMetrics.ANODE_OVERHANG_TOP && e.display_name === StatsDisplayNames.MEAN)?.result
    const bottom = resultsAtSlice.find((e) => e.internal_name === AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM && e.display_name === StatsDisplayNames.MEAN)?.result
    const displayInformation = MetricDisplayNames.find((e) => e.internal_name === AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY)

    if (top && bottom) {
        const mean = (top + bottom) / 2
        const asymmetryPercent = (top - mean) / mean * 100
        const id = getIdFromInternalName(AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY, availableMetrics)
        const asymmetryItem = {
            id: id,
            internal_name: AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY,
            display_name: displayInformation?.display_name || "",
            result: asymmetryPercent,
            display: visibleInspectionMetricIds.includes(id),
            description: displayInformation?.description || "",
            display_units: metricDisplayUnitSliceViewer(AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY),
            color: colorAnnotation(AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY),
            group_id: groupingId(AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY).id,
            group_name: groupingId(AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY).group_display_name
        } as AutomatedInspectionTableData
        return asymmetryItem
    }
    return null
}

export const getAnodeAndCathodeMetrics = (
    visibleInspectionMetricIds: number[],
    availableMetrics: MeasurementDimensions[],
    rawCathodeTraceResults: RawCathodeTraceResults[],
    rawElectrodeOverhangTraceResults: RawElectrodeOverhangTraceResults[],
    metrics: MeasurementDimensions[],
    slice_id: number | undefined
) => {
    let resultsAtSlice: AutomatedInspectionTableData[] = []
    const getMetricBase = (availableMetrics: MeasurementDimensions[], metric: MeasurementDimensions) => {
        const metricId = getIdFromInternalName(metric.metric_internal_name, availableMetrics)
        if (!metricId) {
            throw new Error(`Metric not found: ${metric.metric_internal_name}`);
        }
        return {
            id: metricId,
            internal_name: metric.metric_internal_name,
            description: metric.description,
            display_units: metricDisplayUnitSliceViewer(metric.metric_internal_name),
            group_id: groupingId(metric.metric_internal_name).id,
            group_name: groupingId(metric.metric_internal_name).group_display_name
        };
    };
    type MetricBase = ReturnType<typeof getMetricBase>

    const addMetricResults = (metricBase: MetricBase, metricResults: Stats) => ([
        {
            ...metricBase,
            display: getMetricWithStats(visibleInspectionMetricIds).includes(metricBase.id),
            id: metricBase.id * 1000 + StatsIds.MIN,
            result: metricResults.min,
            display_name: StatsDisplayNames.MIN,
            description: metricBase.description + " - " + StatsDisplayNames.MIN + " of slice",
            color: `${overlayColorScheme.orange}98`
        },
        {
            ...metricBase,
            id: metricBase.id * 1000 + StatsIds.MAX,
            display: getMetricWithStats(visibleInspectionMetricIds).includes(metricBase.id),
            result: metricResults.max,
            display_name: StatsDisplayNames.MAX,
            description: metricBase.description + " - " + StatsDisplayNames.MAX + " of slice",
            color: `${overlayColorScheme.green}98`
        },
        {
            ...metricBase,
            id: metricBase.id * 1000 + StatsIds.MEAN,
            display: getMetricWithStats(visibleInspectionMetricIds).includes(metricBase.id),
            result: metricResults.mean,
            display_name: StatsDisplayNames.MEAN,
            description: metricBase.description + " - " + StatsDisplayNames.MEAN + " of slice",
            color: `${overlayColorScheme.blue}98`
        },
        {
            ...metricBase,
            id: metricBase.id * 1000 + StatsIds.STD_DEV,
            display: getMetricWithStats(visibleInspectionMetricIds).includes(metricBase.id),
            result: metricResults.stdDev,
            display_name: StatsDisplayNames.STD_DEV,
            description: metricBase.description + " - " + StatsDisplayNames.STD_DEV + " of slice",
            color: `${overlayColorScheme.blue}98`
        }
    ]);

    const processMetric = (
        metric: MeasurementDimensions | undefined,
        values: RawElectrodeOverhangTraceResults[] | RawCathodeTraceResults[],
        traceKey: string,
    ) => {
        if (metric) {
            try {
                const metricBase = getMetricBase(availableMetrics, metric);
                const metricResults = calculateStats(values.map((e: any) => e[traceKey])); // TODO: any type
                const metricsToAdd = addMetricResults(metricBase, metricResults);
                resultsAtSlice.push(...metricsToAdd);
            } catch (error) {
                console.error(error);
            }
        }
    };
    const cathodeValues = rawCathodeTraceResults.filter((e) => e.slice_id === slice_id)
    const electrodeValues = rawElectrodeOverhangTraceResults.filter((e) => e.slice_id === slice_id)
    let metricsToProcess = []

    if (cathodeValues.length > 0) metricsToProcess.push(...[
        { metric: AutomatedInspectionMetrics.CATHODE_WIDTH, values: cathodeValues, key: 'trace_length' },
        { metric: AutomatedInspectionMetrics.CATHODE_POSITION_TOP, values: cathodeValues, key: 'top_position' },
        { metric: AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM, values: cathodeValues, key: 'bottom_position' },
    ])
    if (electrodeValues.length > 0) metricsToProcess.push(...[
        { metric: AutomatedInspectionMetrics.ANODE_OVERHANG_TOP, values: electrodeValues.filter((e) => e.location === "top"), key: 'trace_length' },
        { metric: AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM, values: electrodeValues.filter((e) => e.location === "bottom"), key: 'trace_length' },
        { metric: AutomatedInspectionMetrics.ANODE_OVERHANG_ALL, values: electrodeValues, key: 'trace_length' }
    ]);

    metricsToProcess.forEach(({ metric, values, key }) => {
        const metricObj = metrics.find((e) => e.metric_internal_name === metric);
        processMetric(metricObj, values, key);
    });

    const asymmetry = getAnodeAsymmetry(resultsAtSlice, visibleInspectionMetricIds, availableMetrics)
    if (asymmetry) resultsAtSlice.push(asymmetry)

    const allAnodesMin = resultsAtSlice.find((e) => e.internal_name === AutomatedInspectionMetrics.ANODE_OVERHANG_ALL && e.display_name === StatsDisplayNames.MIN)
    const anodeOverhangViolationThreshold = 0
    if (allAnodesMin) {
        resultsAtSlice.push({
            id: allAnodesMin.id * 1000 + StatsIds.MIN + 10,
            internal_name: AutomatedInspectionMetrics.INSUFFICIENT_ANODE_OVERHANG,
            result: allAnodesMin.result <= anodeOverhangViolationThreshold ? 1 : 0,
            display: true,
            description: MetricDisplayNames.find((e) => e.internal_name === AutomatedInspectionMetrics.INSUFFICIENT_ANODE_OVERHANG)?.description || "",
            display_name: MetricDisplayNames.find((e) => e.internal_name === AutomatedInspectionMetrics.INSUFFICIENT_ANODE_OVERHANG)?.display_name || "",
            display_units: "",
            color: allAnodesMin.color,
            group_id: allAnodesMin.group_id,
            group_name: allAnodesMin.group_name
        })
    }

    return resultsAtSlice;
}



export const getAutomatedInspectionTableData = (
    rawGeneralMetrics: RawGeneralInspectionResults[],
    slice_id: number | undefined,
    visibleInspectionMetricIds: number[],
    availableMetrics: MeasurementDimensions[],
    sliceOrientation: string,
    rawCathodeTraceResults: RawCathodeTraceResults[],
    rawElectrodeOverhangTraceResults: RawElectrodeOverhangTraceResults[],
) => {
    const metrics = availableMetrics.filter((e) => e.orientation === sliceOrientation)

    // first, most metrics are 1:1 shape to value:
    const metricsAtSlice = rawGeneralMetrics
        .filter((e) => e.slice_id === slice_id)
        .map((e) => {
            const metricMetadata = availableMetrics.find((m) => m.metric_id === e.metric_id) || {} as MeasurementDimensions
            return {
                id: e.metric_id, // note could have overlapping ids with manual measurements?
                internal_name: metricMetadata?.metric_internal_name || "",
                result: e.value,
                display: visibleInspectionMetricIds.includes(e.metric_id),
                description: metricMetadata?.description || "",
                display_name: metricMetadata?.metric_display_name || "",
                display_units: metricDisplayUnitSliceViewer(metricMetadata?.metric_internal_name),
                color: colorAnnotation(metricMetadata?.metric_internal_name as AutomatedInspectionMetrics),
                group_id: groupingId(metricMetadata?.metric_internal_name).id, // Dropping grouping for now
                group_name: groupingId(metricMetadata?.metric_internal_name).group_display_name
            } as AutomatedInspectionTableData
        })

    // can wall thickness is a special case to be calculated:
    const canWallThickness = addCanWallThickness(metricsAtSlice, metrics, visibleInspectionMetricIds)
    if (canWallThickness) metricsAtSlice.push(canWallThickness)

    // anodes and cathodes are a special case to be calculated:
    const anodesAndCathodes = getAnodeAndCathodeMetrics(visibleInspectionMetricIds, metrics, rawCathodeTraceResults, rawElectrodeOverhangTraceResults, metrics, slice_id)
    if (anodesAndCathodes) metricsAtSlice.push(...anodesAndCathodes)

    return metricsAtSlice as AutomatedInspectionTableData[]
}



export const groupingId = (metricName: string) => {
    enum MetricGroupNames {
        // This is for the grouping of metrics in the Slice view tables (tables under the overlays)
        OVERHANG_ALL = "Electrode overhang - all",
        OVERHANG_TOP = "Electrode overhang - top of cell",
        OVERHANG_BOTTOM = "Electrode overhang - bottom of cell",
        CATHODE_WIDTH = "Cathode width",
        CATHODE_POSITION_TOP = "Cathode position - top of cell",
        CATHODE_POSITION_BOTTOM = "Cathode position - bottom of cell",
        CORE = "Jellyroll core",
        CAN = "Can",
        CRIMP = "Crimp",
    }
    switch (metricName) {
        // Axial:
        case AutomatedInspectionMetrics.ANODE_OVERHANG_TOP:
            return { id: 1, group_display_name: MetricGroupNames.OVERHANG_TOP };
        case AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM:
            return { id: 2, group_display_name: MetricGroupNames.OVERHANG_BOTTOM };
        case AutomatedInspectionMetrics.CATHODE_WIDTH:
            return { id: 3, group_display_name: MetricGroupNames.CATHODE_WIDTH };
        case AutomatedInspectionMetrics.CATHODE_POSITION_TOP:
            return { id: 4, group_display_name: MetricGroupNames.CATHODE_POSITION_TOP };
        case AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM:
            return { id: 5, group_display_name: MetricGroupNames.CATHODE_POSITION_BOTTOM };
        case AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY:
        case AutomatedInspectionMetrics.ANODE_OVERHANG_ALL:
        case AutomatedInspectionMetrics.INSUFFICIENT_ANODE_OVERHANG:
            return { id: 6, group_display_name: MetricGroupNames.OVERHANG_ALL };
        case AutomatedInspectionMetrics.LEFT_CRIMP_HEIGHT:
        case AutomatedInspectionMetrics.RIGHT_CRIMP_HEIGHT:
            return { id: 9, group_display_name: MetricGroupNames.CRIMP };
        // Radial:
        case AutomatedInspectionMetrics.CORE_AREA:
        case AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX:
        case AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC:
        case AutomatedInspectionMetrics.ELECTRODE_BUCKLING_DETECTED:
        case AutomatedInspectionMetrics.CORE_CONCENTRICITY:
        case AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER:
        case AutomatedInspectionMetrics.ANODE_TAB_CURVATURE:
            return { id: 7, group_display_name: MetricGroupNames.CORE };
        case AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN:
        case AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN:
        case AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN:
        case AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX:
        case AutomatedInspectionMetrics.CAN_MAX_DENTING:
            return { id: 8, group_display_name: MetricGroupNames.CAN };
        default:
            return { id: 0, group_display_name: "" };
    }
}
