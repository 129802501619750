import { Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuthTokenAndAccessApi } from '../../auth/authHooks';
import BasicLoadingIndicator from '../../components/BasicLoadingIndicator';
import { PostingApiStatus } from '../../types';
import { formatIsoDateTime, getLocalTimezoneAcronym } from '../../utils';
import { ApiEndpoints } from '../../utils/apiUtils';


interface ScansMissingSns {
    scan_id: number;
    file: string;
    post_processing_completed: Date;
    serial_number: string;
}

interface SnsToUpdate {
    scan_id: number;
    serial_number: string;
}


export default function ScansWithoutSerialNumber() {
    const [scans, setScans] = useState<ScansMissingSns[]>([]);
    const [uploadState, setUploadState] = useState(PostingApiStatus.FALSE);
    const [loading, setLoading] = useState(true);
    const { fetchData, postData } = useAuthTokenAndAccessApi();

    useEffect(() => { fetchScans() }, []);

    const fetchScans = async () => {
        try {
            setScans([]);
            setLoading(true);
            const response = await fetchData(ApiEndpoints.OPERATOR_SCANS_MISSING_SNS);
            const scanData = response.data as ScansMissingSns[];
            setScans(scanData.sort((a, b) => b.scan_id - a.scan_id));
        } catch (error) {
            console.error("Error fetching scans:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSerialNumberChange = (scan_id: number, serial_number: string) => {
        const updatedScan = scans.find((scan) => scan.scan_id === scan_id)
        if (updatedScan) {
            updatedScan.serial_number = serial_number;
            const updatedScans = scans.map((scan) => {
                return scan.scan_id === scan_id ? updatedScan : scan;
            });
            setScans(updatedScans);
        }
    }
    const serialNumbersToSubmit: SnsToUpdate[] = scans
        .filter(scan => scan.serial_number)
        .map((scan) => {
            return { scan_id: scan.scan_id, serial_number: scan.serial_number };
        })

    const validateSerialNumbers = (): boolean => {
        return serialNumbersToSubmit.every(e => !e.serial_number.includes(' '));
    };

    const handleSubmit = async () => {
        setUploadState(PostingApiStatus.LOADING);
        try {
            const response = await postData(ApiEndpoints.OPERATOR_SCANS_MISSING_SNS, serialNumbersToSubmit);
            if (response.status === 200) {
                setUploadState(PostingApiStatus.SUCCESS);
            } else {
                setUploadState(PostingApiStatus.ERROR);
            }
        } catch (error) {
            console.error("Error submitting data:", error);
            setUploadState(PostingApiStatus.ERROR);
        } finally {
            setTimeout(() => {
                setUploadState(PostingApiStatus.FALSE);
                fetchScans();
            }, 1000);

        }

    };

    if (loading) { return <BasicLoadingIndicator message='Checking for scans with missing SNs' />; }
    if (scans.length === 0) { return <Typography variant="body1" sx={{ m: 3 }}>All scans have serial numbers associated with them</Typography>; }
    return (
        <Box sx={{ maxWidth: 1300 }}>
            <Typography variant="body1" sx={{ mt: 3 }}>
                These scans have no serial number associated with them.
                <br />
                Enter the serial number for each scan (spaces are not allowed), and click submit to add the serial number to the scan.
                <br />
                If you do not know the serial number, leave the field blank and you can add it later.
            </Typography>
            <TableContainer sx={{ mt: 3 }}>
                <Table size="small" sx={{ minWidth: 900 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Filename</TableCell>
                            <TableCell>Datetime of processing completion ({getLocalTimezoneAcronym()})</TableCell>
                            <TableCell>Scan ID</TableCell>
                            <TableCell>Serial Number</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scans.map((scan) => (
                            <TableRow key={scan.scan_id}>
                                <TableCell>{scan.file}</TableCell>
                                <TableCell>{formatIsoDateTime(scan.post_processing_completed, true)} </TableCell>
                                <TableCell>{scan.scan_id}</TableCell>
                                <TableCell>
                                    <TextField
                                        value={scan.serial_number || ''}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={e => handleSerialNumberChange(scan.scan_id, e.target.value)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Grid item sx={{ mt: 3, mb: 3 }}>
                    <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={
                            uploadState === PostingApiStatus.LOADING ||
                            !validateSerialNumbers() ||
                            serialNumbersToSubmit.length === 0
                        }
                    >
                        Submit Serial numbers
                    </Button>
                </Grid>
            </TableContainer >
        </Box >
    );
}
